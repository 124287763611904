// About.js
import React from 'react';
import './About.scss';

const About = () => {
    return (
        <div className="about">
            <div id="about" className="map-container">
                <iframe 
                    title="Mapa"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13330.387112345068!2d19.820755!3d45.238129!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b10243d42cac3%3A0xd609db5b1d7c48e3!2sLaze%20Lazarevi%C4%87a%204%2C%20Novi%20Sad%2021000%2C%20Serbia!5e1!3m2!1sen!2sus!4v1727806728233!5m2!1sen!2sus" 
                    width="600" 
                    height="450" 
                    style={{ border: 0 }} 
                    allowFullScreen="" 
                    loading="lazy" 
                ></iframe>
            </div>

            <div className="info-container">
                <h2>O Nama</h2>
                <p>U našem preduzeću, specijalizovani smo za pranje nameštaja i tepiha u Novom Sadu. Naš cilj je pružiti vrhunsku uslugu koja će zadovoljiti sve potrebe naših klijenata.</p>
                <p>Naša adresa: Laze Lazarevića 4, Novi Sad</p>
                <p>Naša misija je da obezbedimo čiste i sveže prostorije, pomažući vam da se osećate prijatno u svom domu ili poslovnom prostoru. Koristimo najsavremenije tehnike i ekološki prihvatljive proizvode za pranje nameštaja i tepiha.</p>
            </div>
        </div>
    );
};

export default About;
