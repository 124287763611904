// Cenovnik.js
import React from 'react';
import { FaStar, FaHandsHelping, FaCheckCircle, FaTruck } from 'react-icons/fa';
import './Cenovnik.scss';

const Cenovnik = () => {
    return (
        <div className="cenovnik">
            <h2>Cenovnik Usluga</h2>
            <div id="cenovnik" className="icon-section">
                <div className="icon-item">
                    <FaStar className="icon" />
                    <h3>Kvalitetna Usluga</h3>
                    <p>Obezbeđujemo vrhunski kvalitet u svakom poslu.</p>
                </div>
                <div className="icon-item">
                    <FaHandsHelping className="icon" />
                    <h3>Posvećenost Klijentima</h3>
                    <p>Svakom klijentu pristupamo sa pažnjom i brigom.</p>
                </div>
                <div className="icon-item">
                    <FaCheckCircle className="icon" />
                    <h3>Garancija Zadovoljstva</h3>
                    <p>Vaše zadovoljstvo je naš prioritet!</p>
                </div>
                <div className="icon-item">
                    <FaTruck className="icon" />
                    <h3>Brza Dostava</h3>
                    <p>Usluge dostavljamo brzo i efikasno.</p>
                </div>
            </div>

            <div className="prices">
                <div className="price-card">
                    <h3>Pranje Tepiha</h3>
                    <p>320 din/m²</p>
                    <p>350 din/m² – Vuneni Tepisi</p>
                    <p>300 din prevoz tepiha u oba smera.</p>
                </div>

                <div className="price-card">
                    <h3>Pranje Dušeka</h3>
                    <p>2400 din – Bračni dušek obostrano</p>
                    <p>1200 din – Dušek za jednu osobu</p>
                    <p>300 din – Jastuci</p>
                </div>

                <div className="price-card">
                    <h3>Pranje Nameštaja</h3>
                    <p>650 – 700 din – Sedeće mesto</p>
                    <p>3000 – 4200 din – Ugaone garniture</p>
                    <p>700 – 800 din – Fotelje</p>
                    <p>350 – 450 din – Stolice</p>
                    <p>350 – 450 din – Taburei</p>
                    <p>1300 din – Dvosed</p>
                    <p>1950 din – Trosed</p>
                </div>
            </div>
        </div>
    );
};

export default Cenovnik;
