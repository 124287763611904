import './App.css';
import Navbar from './components/main/navbar'; // Uvezi Navbar komponentu
import Hero from './components/sections/hero/hero'; // Uvezi Navbar komponentu
import Usluge from './components/sections/usluge/usluge'; // Uvezi Navbar komponentu
import Cenovnik from './components/sections/cenovnik/cenovnik'; // Uvezi Navbar komponentu
import About from './components/sections/about/about'; // Uvezi Navbar komponentu
import Footer from './components/main/footer'; // Uvezi Navbar komponentu

function App() {
  return (
    <div className="App">  
        <Navbar />
        <Hero />
        <Usluge />
        <Cenovnik />
        <About />
        <Footer />
    </div>
  );
}

export default App;
