// Hero.js
import React from 'react';
import './Hero.scss';

const Hero = () => {
    return (
        <div className="hero" style={{ backgroundImage: `url(https://imgur.com/RzJdDS4.png)` }}>
            <div className="hero-content">
                <h1 className="hero-header">PRANJE TEPIHA NOVI SAD</h1>
                <p className="hero-subheader">Pružamo vrhunske usluge za vas.</p>
                <a href="#kontakt"> {/* Link for "Kontakt" button */}
                <button className="navbar-contact">Kontakt</button>
            </a>
            </div>
        </div>
    );
};

export default Hero;
