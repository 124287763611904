// Footer.js
import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div id="kontakt" className="footer-left">
                <p>All rights reserved © 2024</p>
            </div>
            <div className="footer-center">
                <div className="social-icons">
                    <a href="https://www.instagram.com/tepih_spa_servis/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="icon" />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61558349196325" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="icon" />
                    </a>
                    <a href="https://www.tiktok.com/@tepih.spa.servis" target="_blank" rel="noopener noreferrer">
                        <FaTiktok className="icon" />
                    </a>
                </div>
            </div>
            <div className="footer-right">
                <p>Tel: +381 66 215 115</p>
                <p>Adresa: Laze Lazarevića 4, Novi Sad</p>
            </div>
        </footer>
    );
};

export default Footer;
