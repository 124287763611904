import React from 'react';
import './Navbar.scss';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src="https://imgur.com/c9Bxyih.png" alt="Logo" />
            </div>
            <ul className="navbar-links">
                <li><a href="#usluge">Usluge</a></li>
                <li><a href="#cenovnik">Cenovnik</a></li>
                <li><a href="#about">O Nama</a></li>
            </ul>
            <a href="#kontakt">
                <button className="navbar-contact">Kontakt</button>
            </a>
        </nav>
    );
};

export default Navbar;
