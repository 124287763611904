import React from 'react';
import './Usluge.scss';

const Usluge = () => {
    const usluge = [
        {
            title: "Dubinsko Pranje tepiha",
            description: "Uz našu uslugu dubinskog pranja tepiha, vaši tepihe dobijaju tretman koji zaslužuju. Naš tim stručnjaka dolazi po vaše tepihe i donosi ih nazad savršeno čiste, mirisne i suve, čime obnavljamo njihov izgled i produžavamo njihov vek trajanja.",
            image: "https://servistepihanovisad.com/wp-content/uploads/usisavanje-tepiha.jpg",
            detail: "Proces pranja tepiha počinje pažljivim usisavanjem koje uklanja prašinu, nečistoće i alergene. Zatim pažljivo tresemo tepihe kako bismo oslobodili sve skrivene čestice. Na taj način, vaš tepih je spreman za dubinsko pranje koje koristi ekološki bezbedne proizvode, osiguravajući da ne oštećujemo niti boje niti vlakna."
        },
        {
            title: "Dubinsko pranje nameštaja",
            description: "Vaš nameštaj zaslužuje da zablista kao nov. Naša usluga dubinskog pranja nameštaja osigurava da svi vaši komadi, od sofe do fotelja, izgledaju savršeno. Samo nas pozovite, i mi dolazimo na vašu adresu, bez obzira gde se nalazite.",
            image: "https://servistepihanovisad.com/wp-content/uploads/pranje-tepiha-600x400-1.webp",
            detail: "Koristimo najnovije tehnike čišćenja i ekološki prihvatljive hemikalije kako bismo osigurali da vaš nameštaj bude temeljno očišćen, bez oštećenja ili mirisa hemikalija. Naš tim se fokusira na svaki detalj, koristeći specijalizovane četke i aparate koji prodire u dubinu tkanine, vraćajući joj prirodnu lepotu."
        },
        {
            title: "Ispiranje i Sušenje",
            description: "Nakon pranja, dolazi ključna faza - ispiranje i sušenje. Ovaj proces je od suštinskog značaja za kvalitet krajnjeg proizvoda. Naš tim koristi centrifuge koje brzo uklanjaju višak vode, sprečavajući oštećenja i stvaranje neprijatnih mirisa.",
            image: "https://servistepihanovisad.com/wp-content/uploads/susenje-tepiha-komora.jpg",
            detail: "Na kraju, tepisi se suše u specijalnim komorama, sa mogućnošću dodavanja mirisnih dodataka po želji klijenta. Naš cilj je da svaki tepih ne samo da izgleda savršeno, već i da mirisne tako da vas podsećaju na svežinu i čistoću."
        }
    ];

    return (
        <div className="usluge">
            <h2>Naše Usluge</h2>
            <div id="usluge" className="usluge-container">
                {usluge.map((usluga, index) => (
                    <div key={index} className={`usluga-card ${index === 0 ? 'left-shift' : index === 1 ? 'right-shift' : 'left-shift-again'}`}>
                        <img src={usluga.image} alt={usluga.title} />
                        <div className="usluga-info">
                            <h3>{usluga.title}</h3>
                            <p>{usluga.description}</p>
                            <p className="usluga-detail">{usluga.detail}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Usluge;
